@import url('../src/styles/fonts.css');

:root {
  --primary: #E1B03B;
  /* --primary: #edce45; */
  --selection: #785e21;
  --white: #F2F2F2;
  --grey: #404040;
  --dark-grey: #262626;
  --black: #0D0D0D;
}

* {
  margin: 0;
  padding: 0;
  -moz-box-sizing: border-box; 
  -webkit-box-sizing: border-box; 
  box-sizing: border-box; 
}

.App {
  background-color: #262626;
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* font-size: calc(10px + 2vmin); */
}

.body {
  display: flex;
  padding: 20px 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 60px;
  align-self: stretch;
}

::selection {
  background: var(--selection);
}

.experience-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 60px;
  min-width: 340px;
  max-width: 1000px;
  width: 80%;
  padding: 0 32px;
  height: auto;
}

.experience-container > div#experience-child {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  /* max-width: 80%; */
  max-width: 470px;
  gap: 60px;
}

.welcome-container {
  display: flex;
  padding: 200px 20px;
  height: 100vh;
}

h1 {
  font-family: "Barlow-Medium";
  font-size: 2rem;
  font-style: normal;
  font-weight: 500;
  /* letter-spacing: 1.68px; */
  text-transform: uppercase;
  color: var(--primary);
}

h2 {
  font-family: "Barlow-Medium";
  font-size: 1.5rem;
  font-style: normal;
  text-transform: uppercase;
  text-align: center;
  font-weight: 500;
  height: 100%;
  color: #F2F2F2;
  display: flex;
}

h3 {
  font-family: "Barlow-Medium";
  font-size: 1.25rem;
  font-style: normal;
  text-transform: uppercase;
  text-align: start;
  font-weight: 500;
  /* height: 100%; */
  color: var(--white);
  display: flex;
}

hr {
  background-color: var(--primary);
  border: none;
  border-radius: 2px;
  height: 2px;
  width: 120px;
  list-style: none;
}

p {
  font-family: "Roboto-Regular";
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.skills-text {
  /* width: 80%; */
  color: var(--white);
  font-family: "Roboto-Regular";
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  max-width: 400px;
  text-align: center;
}

.skills-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 60px;
  min-width: 340px;
  width: 80%;
  padding: 0 32px;
  height: auto;
  max-width: 1000px;
}

.skills-container > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 48px;
}

.skills-group {
  display: flex;
  gap: 9px;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}

.skills-group > div {
  transition: transform 0.3s ease;
}

.skills-group > div:hover {
  transform: scale(1.1);
}

.skills-group h3 {
  color: var(--white);
}

.courses-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 60px;
  min-width: 340px;
  max-width: 1000px;
  padding: 0px 32px;
  width: 80%;
  height: auto;
}

.courses-container > div{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 60px;
}

div.vertical-line {
  min-width: 2px;
  max-width: 2px;
  width: 2px;
  background-color: var(--grey);
  border-radius: 2px;
}

@media screen and (min-width: 800px){
  
}

@media screen and (min-width: 900px){
  
}

@media screen and (min-width: 1000px){ 
  .body {
    gap: 80px;
  }

  p {
    font-size: 1rem;
  }

  h1 {
    font-size: 2.25rem;
  }

  h2 {
    font-size: 1.75rem;
  }

  h3 {
    font-size: 1.375rem;
  }

  .experience-container {
    flex-direction: row;
    gap: 60px;
    width: 80%;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0px;
  }

  .experience-container > div > div {
    /* min-width: 360px; */
    width: 100%;
  }

  .experience-container hr {
    width: 2px;
    /* min-width: 2px; */
    max-width: 2px;
    height: 200px;
    background-color: var(--grey);
  }

  .courses-container {
    padding: 0px
  }

  .skills-container {
    flex-direction: row;
  }

  .skills-container > * {
    flex: 1;
  }

  .courses-container > div {
    flex-direction: row;
    gap: 80px;
  }
}