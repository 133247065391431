.container {
    display: flex;
    padding: 40px 25px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 18px;
    align-self: stretch;
    color: var(--white);
}

.link {
    all: unset;
    transition: transform 0.3s ease;
}
  
.link:hover {
    cursor: pointer;
    transform: scale(1.2);
}
  
.link:active {
    transform: scale(0.9);
} 
  