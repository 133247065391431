.about-me-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 80%;
    max-width: 1000px;
    align-items: center;
    gap: 48px;
}

.about-me-inner-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 48px;
    width: 100%;
    /* justify-content: center; */
}

.header-and-text-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 30px;
}

.about-me-text {
    text-align: left;
    font-family: "Roboto-Regular";
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    /* max-width: 400px; */
    width: 85%;
    color: var(--white);
}

.adjective-wrapper {
    display: flex;
    min-width: 250px;    
    max-width: 360px;
    width: 70%;
    gap: 20px;
    flex-direction: column;
    justify-content: flex-start;
}

.adjective-info-text {
    color: var(--primary);
    text-align: start;
    align-self: center;
}

.adjective-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 30px;
}

.PlaceHolderImage {
    width: 226px;
    height: 254px; 
    background-color: #D9D9D9;
}

.facial-image {
    width: 250px;
    height: auto;
    transition: transform 0.3s ease;
}

.adjective-slider {
    display: flex;
    min-height: 50px;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
}

.adjective-slots {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.adjective-slots * {
    display: flex;
    justify-content: center;
}

.adjective-slots *.disabled-slot {
    display: none;
    scale: 0.6;
}

.adjective-scroll {
    display: flex;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    max-width: 900px;
}

.scroll-content, .scroll-content-2 {
    display: flex;
    gap: 9px;
    padding: 0px 4.5px;
    animation: horizontal-scroll linear 30s infinite;
}


@keyframes horizontal-scroll {
    0% {
        transform: translateX(0%);        
    }

    100% {
        transform: translateX(-100%);        
    }
}

.skills-group > div {
    transition: transform 0.3s ease;
}

@media screen and (min-width: 500px) {
    .adjective-slots * {
        width: 33.33%;
    }

    .adjective-slots *.disabled-slot {
        display: flex;
        scale: 0.6;
    }
}

@media screen and (min-width: 750px) {
    .about-me-container {
        gap: 60px;
    }
    .about-me-inner-container {
        flex-direction: row;
    }

    .header-and-text-container {
        align-items: start;
        
    }

    .about-me-text {
        text-align: start;
    }

    .adjective-info-text {
        align-self: flex-start;
    }

    
    .facial-image:hover {
        transform: scale(1.1);
    }
}


  