header {
    display: flex;
    padding: 25px;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    align-items: center;
}

.outer-container {
    display: flex;
    width: 100%;
    max-width: 1000px;
    justify-content: space-between;
    align-items: center;
}

.nav-menu {
    list-style: none;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 60px;
    left: 0;
    padding: 10px 25px;
    gap: 5px;
    width: 100%;
    align-items: flex-start;
    background-color: var(--dark-grey);
    transform: translateY(-50%) scaleY(0);
    transition: transform 0.3s ease-in-out;
    box-shadow: 0px 4px 8px 0px var(--black);
}

.nav-menu.active {
    display: flex;
    transform: translateY(0%);
}

.nav-item {
    width: 100%;
}
  
.nav-item a {
    text-decoration: none;
}

.nav-menu button {
    background-color: var(--dark-grey);
    color: var(--white);
    border-color: var(--white);
    width: 100%;
}

.inner-container {
    display: none;
    padding: 5px 0px;
    flex-direction: column;
    align-items: flex-end;
    gap: 5px;
    align-self: stretch;
}

.inner-container button {
    background-color: var(--dark-grey);
    color: var(--white);
    border-color: var(--white);
    width: 100%;
}

.welcome-text {
    display: flex;
    width: 400px;
    max-width: 80%;
    max-height: 100%;
    justify-content: center;
    font-size: 1.75rem;
    align-items: center;
    margin: auto;
    transition: transform 0.3s ease;
    color: var(--primary);
    text-align: center;
}

.scroll-text {
    color: var(--white);
}

.icon {
    color: var(--white);
    margin-bottom: 30px;
}

.menu-icon {
    color: var(--white);
}

@media screen and (min-width: 640px) {
    .welcome-text {
        font-size: 2rem;
    }

    .menu-icon {
        display: none;
    }

    .inner-container {
        display: flex;
        flex-direction: row;
    }

    .welcome-text:hover {
        transform: scale(1.1);
    }
}

@media screen and (max-height: 500px) {
    .welcome-text {
        overflow: hidden;
        font-size: 1.5rem;
    }
}