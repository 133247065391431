@font-face {
    font-family: 'Barlow-Medium';
    src: url('../assets/fonts/BarlowCondensed-Medium.ttf') format('truetype'); /* Adjust the file path and format as needed */
    /* font-weight: 500;
    font-style: normal; */
}

@font-face {
    font-family: 'Roboto-Regular';
    src: url('../assets/fonts/Roboto-Regular.ttf') format('truetype'); /* Adjust the file path and format as needed */
    /* font-weight: 500;
    font-style: normal; */
}

@font-face {
    font-family: 'Roboto-Light';
    src: url('../assets/fonts/Roboto-Light.ttf') format('truetype'); 
}