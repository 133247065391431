.component-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 80%;
    max-width: 1000px;
    min-width: 810px;
    /* padding: 30px 0px; */
    gap: 10px;
}

.Container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 340px;
    width: 80%;
    margin: auto;
    /* height: 300px; */
    /* gap: 5px; */
}

.list-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 5px;
}

.project-photo {
    /* display: inline-block; */
    max-width: 100%;
    max-height: 100%;     
    transition: transform 0.3s ease;
}

.btn {
    background-color: var(--dark-grey);
    /* background-color: #0D0D0D; */
    border: none;
    color: var(--primary);
    width: fit-content;
    height: fit-content;
    display: flex;
    margin: 0px;
    padding: 0px;
}

.btn:hover {
    background-color: #171717;
    /* border: 1px solid #E1B03B; */
    color: var(--primary);
}

.dot-indicators {
    display: flex;
    justify-content: center;
    padding: 15px 0 0;
    gap: 10px;
}

.dot {
    height: 10px;
    width: 10px;
    background-color: var(--grey);
    border-radius: 50%;
}

.dot.active {
    background-color: var(--primary)
}

@media screen and (min-width: 1000px) {
    .Container {
        /* flex: 1; */
        width: 90%;
        /* height: 100%; */
        height: calc( 100% - 25px );
    }

    .project-card-container {
        width: 50%;
    }

    .project-photo:hover {
        transform: scale(1.1);
    }
}