/* @import url('./fonts.css'); */


.btn {
    background-color: var(--primary);
    color: var(--black);
    padding: 4px 8px;
    border: 1px solid var(--black);
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    align-content: center;
    display: flex;
    height: auto;
    width: fit-content;
    gap: 2px;
}

.btn_text {
    /* background-color: blue; */
    /* font-family: Arial, "Helvetica Neue", Helvetica, sans-serif; */
    font-family: "Roboto-Regular";
    font-size: 0.875rem;
    /* font-style: normal; */
    font-weight: 400;
    /* line-height: 14px; */
    line-height: normal;
    text-align: center;
    /* height: 20px; */
    margin: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 3px;
}

.btn:hover {
    background-color: var(--dark-grey);
    border: 1px solid var(--primary);
    color: var(--primary);
    cursor: pointer;
}

.btn-arrow {
    background-color: var(--dark-grey);
    border: none;
    color: var(--primary);
    width: fit-content;
    height: fit-content;
    display: flex;
    margin: 0px;
    padding: 0px;
}

.btn-arrow:hover {
    background-color: #171717;
    color: var(--primary);
}

.btn-arrow:disabled {
    background-color: var(--dark-grey);
    color: var(--dark-grey);
}
