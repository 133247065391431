.card {
    display: flex;
    flex-direction: column;
    background-color: var(--grey);
    min-width: 258px;
    width: 100%;
    padding: 35px 25px;
    margin: 0 auto;
    max-width: 470px;
    gap: 18px;
}

.skills-container {
    display: flex;
    gap: 5px;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
}

.skills-container * {
    border-color: var(--white);
}

h3 {
    color: var(--primary);
}

.text {
    color: var(--white);
}

@media screen and (min-width: 1000px) {
    .card#project-card{
        background-color: var(--dark-grey);
        max-width: 500px;
        /* width: 400px; */
    }
}